var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"delivery","archiveBladeName":"delivery-archives","bladeName":"deliveries","bladesData":_vm.bladesData,"headers":[
        { text: 'Dispatcher', value: 'seller.companyName', title: 1, searchable: true },
        { text: 'Receiver', value: 'buyer.companyName', title: 2, searchable: true },
        { text: 'Arrival On', value: 'arrivalOn', display: true, subtitle: 1 },
        { text: 'Status', value: 'status', display: true, subtitle: 2 },
        { text: 'Destination', value: 'destinationLocation', textFilter: 'toLocationLine', subtitle: 3 }],"hideActions":"","navigation":"deliveries","title":"Deliveries","useServerPagination":""},scopedSlots:_vm._u([{key:"arrivalOn",fn:function(ref){
        var item = ref.item;
return [(item.deliveredOn != null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toShortDateAndTime")(item.deliveredOn))+" ")]):_c('span',[_vm._v(" Due: "+_vm._s(_vm._f("toShortDateAndTime")(item.dueArrivalOn != null ? item.dueArrivalOn : null))+" ")])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.deliveredOn != null)?_c('span',[_vm._v("Delivered")]):(item.releasedOn != null)?_c('span',[_vm._v("On Its Way")]):_c('span',[_vm._v("Pending")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }