<template>
    <BT-Blade-Items
        addBladeName="delivery"
        archiveBladeName="delivery-archives"
        bladeName="deliveries"
        :bladesData="bladesData"
        :headers="[
            { text: 'Dispatcher', value: 'seller.companyName', title: 1, searchable: true },
            { text: 'Receiver', value: 'buyer.companyName', title: 2, searchable: true },
            { text: 'Arrival On', value: 'arrivalOn', display: true, subtitle: 1 },
            { text: 'Status', value: 'status', display: true, subtitle: 2 },
            { text: 'Destination', value: 'destinationLocation', textFilter: 'toLocationLine', subtitle: 3 }]"
        hideActions
        navigation="deliveries"
        title="Deliveries"
        useServerPagination>
        <template v-slot:arrivalOn="{ item }">
            <span v-if="item.deliveredOn != null">
                {{ item.deliveredOn | toShortDateAndTime }}
            </span>
            <span v-else>
                Due: {{ item.dueArrivalOn != null ? item.dueArrivalOn : null | toShortDateAndTime }}
            </span>
        </template>

        <template v-slot:status="{ item }">
            <span v-if="item.deliveredOn != null">Delivered</span>
            <span v-else-if="item.releasedOn != null">On Its Way</span>
            <span v-else>Pending</span>
        </template>

    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Deliveries',
    props: {
        bladesData: null
    }
}
</script>